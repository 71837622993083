import React, { useState, useEffect } from "react";
import FormBox from "../../components/Template/FormBox";
import Input from "../../components/Input";
import * as LE from "../../Styled/LoginStyled";
import * as CS from "../../Styled/CommonStyled";
import LineImage from "../../assets/Line.png";
import { Formik, Field, Form } from "formik";
import { loginValidator } from "../../utiles/validator";
import fileImage from "../../assets/file.png";
import axios, { updateAccessToken } from "../../utiles/axios";
import Checkbox from "../../assets/Checkbox.png";
import loginCheck from "../../assets/loginCheck.png";
import { setAccessToken } from "../../store/action";
import { useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";

const Login = ({ navigate, dispatch }) => {
  const { instance } = useMsal();
  const [loginValues, setLoginValues] = useState({
    email: "",
    password: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const handleLogin = async (values) => {
    // setIsLoading(true);
    // let url = "";
    // let formValues = {
    //   email: values.email?.toLowerCase(),
    //   password: values.password,
    // };
    try {
      if (
        values.email === "sachin@gmail.com" &&
        values.password === "Sachin@123"
      ) {
        // const { data } = await axios.post(url, formValues);
        console.log("Success");
        window.location.reload();
        updateAccessToken("accessToken");
        localStorage.setItem("msgToken", "accessToken");
        dispatch(setAccessToken("accessToken"));
        // updateAccessToken(data?.token?.accessToken);
        // localStorage.setItem("msgToken", data?.token?.accessToken);

        // setIsLoading(false);
      } else {
        setErrorMsg("Wrong Credendials");
      }
    } catch (error) {
      // console.log(error.response.data);
      // setIsLoading(false);
    }
  };

  const access_token = useSelector((state) => state?.access_token);
  console.log(access_token, "access_token access_tokenaccess_token");

  console.log(errorMsg, "errorMsg errorMsgerrorMsg");

  const handleAzure = () => {
    instance
      .loginRedirect({ scopes: ["openid", "profile", "user.read"] })
      .then((loginResponse) => {
        console.log(loginResponse, "loginResponse loginResponseloginResponse");
        // AzureLoginData(loginResponse?.accessToken);
        window.location.reload();
        updateAccessToken(loginResponse?.accessToken);
        localStorage.setItem("msgToken", loginResponse?.accessToken);
        dispatch(setAccessToken(loginResponse?.accessToken));
        // loginSubmit(loginResponse?.account);
      })
      .catch((error) => {});
  };
  return (
    <LE.LoginBox>
      <LE.FormBox>
        <Formik
          enableReinitialize
          initialValues={loginValues}
          validate={loginValidator}
          validateOnChange
          onSubmit={(values) => handleLogin(values, false)}
        >
          {(formikBag) => {
            return (
              <Form style={{ width: "100%" }}>
                <FormBox title={"Hello!"} desc={"Log in to your account."} />
                <Field name="email">
                  {({ field }) => (
                    <Input
                      {...field}
                      placeholder="Enter Email"
                      label="Email"
                      value={formikBag.values.email}
                      onChange={(e) => {
                        formikBag.setFieldValue(
                          "email",
                          e.target.value.replace(/\s/g, "")
                        );
                        setErrorMsg("");
                      }}
                      error={
                        formikBag.touched.email && formikBag.errors.email
                          ? formikBag.errors.email
                          : null
                      }
                    />
                  )}
                </Field>
                <Field name="password">
                  {({ field }) => (
                    <Input
                      {...field}
                      placeholder="Enter Password"
                      label="Password"
                      value={formikBag.values.password}
                      onChange={(e) => {
                        formikBag.setFieldValue(
                          "password",
                          e.target.value?.trim("")
                        );
                        setErrorMsg("");
                      }}
                      error={
                        formikBag.touched.password && formikBag.errors.password
                          ? formikBag.errors.password
                          : null
                      }
                    />
                  )}
                </Field>
                {errorMsg ? (
                  <LE.ErrorMsg>
                    <p>{errorMsg}</p>
                  </LE.ErrorMsg>
                ) : (
                  ""
                )}
                {/* <LE.CheckForRow>
                  <LE.ComBox>
                    <img
                      onClick={() => {
                        formikBag.setFieldValue(
                          "rememberMe",
                          !formikBag.values.rememberMe
                        );
                      }}
                      src={formikBag.values.rememberMe ? Checkbox : loginCheck}
                      alt=""
                    />
                    <LE.RemLable>Remember Me</LE.RemLable>
                  </LE.ComBox>
                  <LE.ForgotButton onClick={() => navigate("/client/forgot")}>
                    Forgot Password?
                  </LE.ForgotButton>
                </LE.CheckForRow> */}
                <CS.LoginButton
                  type="submit"
                  cursor={isLoading}
                  disabled={isLoading}
                  className="buttonload"
                >
                  {" "}
                  {isLoading ? <i class="fa fa-spinner fa-spin"></i> : "Log In"}
                </CS.LoginButton>
                {/* <LE.OrRow>
                  <LE.LineImage>
                    <img src={LineImage} alt="" />
                  </LE.LineImage>
                  <LE.OrText>Or</LE.OrText>
                  <LE.LineImage>
                    <img src={LineImage} alt="" />
                  </LE.LineImage>
                </LE.OrRow> */}
                <CS.LoginButtonTwo
                  style={{ color: "#D7DAE0" }}
                  // onClick={() => navigate("/")}
                  onClick={handleAzure}
                >
                  <img src={fileImage} alt="" />
                  Log in with SSO
                </CS.LoginButtonTwo>
              </Form>
            );
          }}
        </Formik>
      </LE.FormBox>
    </LE.LoginBox>
  );
};

export default Login;
