import React, { useState } from "react";
import styled from "styled-components/macro";
import { useSelector } from "react-redux";
import pencilIcon from "../assets/pencilIcon.png"

const InputBorder = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  position: relative;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  display: flex;
  cursor: pointer;

  img {
    height: 140px;
    width: 140px;
    border-radius: 50%;
    position: relative;
  }

  input {
    font: 1.1rem;
  }
  input::placeholder {
    font-size: 0.8rem !important;
    color: ${(props) => (props.themeColor ? "#94969C" : "#8A94A6")};
  }

  .overlay-layout {
    position: absolute;
    height: 140px;
    width: 140px;
    border-radius: 50%;
    background-color: black;
    opacity: 0;
    transition: opacity 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 20px;
      width: 20px;
      object-fit: contain;
    }
  }

  &:hover .overlay-layout {
    opacity: 0.8;
  }
`;
export const InputBox = styled.label`
  display: flex;
  flex-direction: column;
`;
export const InputLable = styled.label`
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 0.3rem;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
  margin-bottom: 0.5rem;
  text-transform: capitalize;

  span {
    font-weight: 400;
  }
  @media (max-width: 351px) {
    font-size: 0.8rem;
  }
`;
export const InBox = styled.div`
  display: flex;
  color: ${(props) => (props.themeColor ? "#94969C" : "#8A94A6")};
  font-size: 0.8rem;
  align-items: center;
  width: 10%;

  img {
    height: 9px;
    width: 9px;
    object-fit: contain;
    margin-left: 4px;
  }
`;
export const IconBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 45px;
    height: 45px;
    object-fit: contain;
  }
`;
export const UploadText = styled.label`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 400;
  color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
  margin: 0.4rem 0;
  text-transform: capitalize;

  span {
    color: ${(props) => (props.themeColor ? "#CECFD2" : "#f6591c")};
    margin-right: 5px;
    font-weight: 500;
  }
`;
export const UploadDesc = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
  font-size: 0.7rem;
  text-align: center;
`;
export const AddCommonButtonBadge = styled.div`
  color: #ffffff;
  text-transform: capitalize;
  font-size: 0.8rem;
  font-weight: 600;
  background-color: #f6591c;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0.85rem 0.7rem 0.85rem 0.7rem;
  gap: 0.5rem;
  cursor: pointer;

  img {
    height: 15px;
    width: 15px;
    object-fit: contain;
    margin-right: 5px;
  }

  @media (max-width: 769px) {
    margin-top: 1rem;
  }
`;
export default function Input(props) {
  const { error, accepTed, other, others, alertMsg, profilePhoto } = props;
  const theme = useSelector((state) => state?.isDark);
  const uploadRef = React.useRef();
  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const files = Array.from(e.dataTransfer.files);

      // Filter out non-image files
      const imageFiles = files.filter(
        (file) =>
          file.type.startsWith(accepTed) ||
          file.type === other ||
          file.type === others
      );

      if (imageFiles.length > 0) {
        props.onChange({ target: { files: imageFiles } });
      } else {
        // Handle the case where non-image files are dropped
        alert(alertMsg);
      }

      e.dataTransfer.clearData();
    }
  };
  return (
    <>
      <InputBox htmlFor="picos">
        <InputBorder error={error} themeColor={theme}>
          <img src={profilePhoto} alt="" />
          <div className="overlay-layout">
            <img src={pencilIcon} alt=""/>
          </div>
          <input {...props} />
        </InputBorder>
      </InputBox>
    </>
  );
}
