import React, { useState } from "react";
import styled from "styled-components/macro";
import errorImage from "../assets/error.png";
import downArrowIcon from "../assets/downArrow.png";

const InputBorder = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  border: ${(props) => (props.error ? "1px solid red" : "1px solid #bbbbbb")};
  border-radius: 10px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  height: auto;
  padding: 12px 10px;
  border-radius: 8px;
  width: 100%;
  display: flex;

  img {
    height: 18px;
    width: 18px;
  }

  input {
    font: 1.1rem;
    color: #FFFFFF;
  }
  ::placeholder {
    font-size: 1.1rem;
  }
`;
export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
`;
export const InputLable = styled.label`
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 0.3rem;
  color: #edeef1;
  margin-bottom: 0.5rem;
  text-transform: capitalize;

  @media (max-width: 351px) {
    font-size: 0.8rem;
  }
`;
export const InBox = styled.div`
  display: flex;
  /* color: #363a44; */
  color: ${(props) => (props.themeColor ? "#94969C" : "#8A94A6")};
  font-size: 1rem;
  align-items: center;
  width: 10%;

  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    margin-left: 4px;
  }
`;
export default function Input(props) {
  const { error, type, icon, noBorderBottom, label } = props;
  const [showPassword, setShowPassword] = useState(false);
  // const theme = useSelector((state) => state?.isDark);

  return (
    <>
      <InputBox>
        <InputLable>{label}</InputLable>
        <InputBorder error={error}>
          {icon ? (
            <InBox>
              IN <img src={downArrowIcon} alt="" />
            </InBox>
          ) : (
            ""
          )}
          <input
            {...props}
            type={type ? (showPassword ? "text" : type) : "text"}
            style={{
              textAlign: "left",
              border: "none",
              outline: "none",
              width: "90%",
              // backgroundColor: theme ? "#161b26" : "#FFFFFF",
              color: "#161b26",
            }}
          />
          {type === "password" ? (
            <i
              onClick={() => setShowPassword(!showPassword)}
              className={`fa ${
                !showPassword ? "ph ph-eye-slash" : "ph ph-eye"
              }`}
            ></i>
          ) : (
            ""
          )}
          {error ? <img src={errorImage} alt="" /> : ""}
        </InputBorder>
        {error ? (
          <p
            style={{
              paddingTop: 5,
              fontSize: 13,
              color: "red",
              textAlign: "left",
            }}
          >
            {error}
          </p>
        ) : null}
      </InputBox>
    </>
  );
}
