import React from "react";
import * as LE from "../../Styled/LoginStyled";
import editIcon from "../../assets/edit.png";

const FormBox = ({ title, desc, iCon, greeniCon, marginBot, edito, navigate }) => {
  return (
    <LE.ComFormBox>
      {iCon ? (
        <LE.IconRow>
          <LE.IconBox>
            <img src={iCon} alt="" />
          </LE.IconBox>
        </LE.IconRow>
      ) : (
        ""
      )}
      {greeniCon ? (
        <LE.IconRow>
          <LE.IconBox style={{ backgroundColor: "#EDFCF3" }}>
            <img src={greeniCon} alt="" />
          </LE.IconBox>
        </LE.IconRow>
      ) : (
        ""
      )}
      <LE.FormTitle>{title}</LE.FormTitle>
      <div style={{ display: "flex" }}>
        <LE.FormDesc style={{ marginBottom: marginBot }}>{desc}</LE.FormDesc>
        {edito ? (
          <LE.EditIcon>
            <img src={editIcon} onClick={() => {
              navigate(-1)
            }} alt="" />
          </LE.EditIcon>
        ) : (
          ""
        )}
      </div>
    </LE.ComFormBox>
  );
};

export default FormBox;
