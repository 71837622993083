import React, { useEffect, useRef, useState } from "react";
import "./MessageList.css";
import FullScreenImage from "./FullScreenImage";
import * as DE from "./DashEle";
import { STATIC_URL } from "../constants/Statics";
import moment from "moment";
import MoreLess from "../components/MoreLess";

const MessageList = ({ messageList, currentPage, totalCount, feildLoading, handleCurrentPage }) => {
  console.log(messageList, "messageList");
  console.log(currentPage, "currentPage");
  console.log(totalCount, "totalCount");
  
  const sentinelRef = useRef();
  const [profileZoomViewOpen, setProfileZoomViewOpen] = useState(false);

  const [zoomedImage, setZoomedImage] = useState(null);

  const handleImageClick = (imageUrl) => {
    setZoomedImage(imageUrl);
    setProfileZoomViewOpen(true);
  };

  const handleCloseZoomView = () => {
    setZoomedImage(null);
    setProfileZoomViewOpen(false);
  };
  setTimeout(() => {
    let el = document.getElementById("chat-container");
    if (el && el.scrollHeight) {
      el.scrollTo(0, el.scrollHeight);
    }
  }, 100);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          currentPage < totalCount &&
          !feildLoading
        ) {
          handleCurrentPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 1.0 }
    );

    if (sentinelRef.current) observer.observe(sentinelRef.current);

    return () => observer.disconnect();
  }, [feildLoading, currentPage, totalCount]);
  return (
    <DE.MessgageBox id="chat-container">
      
      <div ref={sentinelRef} style={{ height: "1px", width: "100%" }}></div>
      {messageList?.map((message, ind) => (
        <div
          className={`MessageBubbleContainer ${
            message.type !== "received" ? "isUsers" : ""
          }`}
          style={{
            alignItems: message.type !== "received" ? "flex-end" : "flex-start",
          }}
          key={ind} // Add a unique key for each message
        >
          <div
            className={`MessageBubble ${
              message.type !== "received" ? "isUsers" : ""
            }`}
          >
            <MoreLess desc={message.body} theme={false} />
            {/* {message.body} */}
            {message.type !== "received" ? null : (
              <span style={{ padding: "0px", paddingLeft: "5px", fontSize: 9 }}>
                {moment(message.timestamp).format("HH:mm")}
              </span>
            )}
            {message.type === "received" ? null : (
              <span style={{ padding: "0px", paddingLeft: "5px", fontSize: 9 }}>
                {moment(message.timestamp).format("HH:mm")}
              </span>
            )}
            <div
              className={`MessageTime ${
                message.type !== "received" ? "isUsers" : ""
              }`}
            >
              {message.addedOn}
            </div>
            {message.type !== "received" ? (
              <div className="MsgSeenTick">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="13"
                  viewBox="0 0 17 13"
                  fill="none"
                >
                  <path
                    d="M1 7.74641L5.09603 11.8424L15.9385 1"
                    stroke="#CE5988"
                  />
                  <path d="M5.18311 7.96525L10.9657 2.18262" stroke="#CE5988" />
                </svg>
              </div>
            ) : null}
          </div>
        </div>
      ))}
      
    </DE.MessgageBox>
  );
};

export default MessageList;
