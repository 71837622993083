import { Link } from "react-router-dom";
import styled from "styled-components/macro";

export const PopUpContainer = styled.div`
  width: 100%;
  height: 100vh;
  /* height: 400px; */
  min-width: 400px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  overflow-y: scroll;
  padding: 2rem 0;
  margin-bottom: 1rem;

  ::-webkit-scrollbar {
    width: 5px; /* Adjust the width as needed */
  }

  /* Set the styles for the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: #cfd0d1;
    /* background: linear-gradient(#1f97fc , #f04c71); */
    border-radius: 3px; /* Rounded corners for the thumb */
  }

  /* Set the styles for the scrollbar track on hover */
  ::-webkit-scrollbar-track:hover {
  }

  /* Set the styles for the scrollbar thumb on hover */
  ::-webkit-scrollbar-thumb:hover {
  }
`;
export const InnerPopup = styled.div`
  width: 50%;
`;
export const PopTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  font-size: 1.8rem;
  font-weight: 400;
  margin-bottom: 1.3rem;
`;
export const PopInputBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 1rem;
`;
export const PopLabel = styled.div`
  width: 100%;
  text-align: left;
`;
export const PopInput = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.6rem;

  input {
    width: 100%;
    padding: 12px 0;
  }
  i {
    cursor: pointer;
  }
`;

export const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;
export const ButtonRowBox = styled.div`
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    padding: 10px 12px;
    font-size: 0.9rem;
    color: white;
    background-color: #57871f;
    border-radius: 8px;
    border: none;
    outline: none;
    cursor: pointer;

    :hover {
      opacity: 0.7;
    }
  }
`;
export const ButtonRowBoxs = styled.div`
  /* width: 100%; */
  display: flex;
  justify-content: right;
  align-items: center;

  button {
    padding: 10px 10px;
    font-size: 0.6rem;
    color: white;
    background-color: #57871f;
    border-radius: 8px;
    border: none;
    outline: none;
    cursor: pointer;
    :hover {
      opacity: 0.7;
    }
  }
`;
export const OrText = styled.div`
  width: 100%;
  text-align: center;
  font-size: 0.9rem;
  color: gray;
  margin-top: 1rem;

  div {
    color: #57871f;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
  }
`;
export const ResendRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 5rem;

  div {
    font-size: 1rem;
    font-weight: 500;
    color: red;
    cursor: pointer;
    text-transform: capitalize;
    margin-bottom: 1rem;
  }
`;
export const ParentCon = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  overflow: hidden;
  height: 400px;
  width: 100%;
  min-width: 400px !important;
  max-width: 700px !important;
`
export const ModalCon = styled.div`
  display: flex;
  justify-content: space-between;
`
export const leftSidebar = styled.div`
  width: 30%;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  text-align: left;
  ::-webkit-scrollbar {
    width: 5px; /* Adjust the width as needed */
  }

  /* Set the styles for the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    /* background: linear-gradient(#1f97fc , #f04c71); */
    border-radius: 3px; /* Rounded corners for the thumb */
  }

  /* Set the styles for the scrollbar track on hover */
  ::-webkit-scrollbar-track:hover {
  }

  /* Set the styles for the scrollbar thumb on hover */
  ::-webkit-scrollbar-thumb:hover {
  }
`
export const LeftMenu = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  color: white;
  padding: 4px 7px;
  color: black;
  cursor: pointer;

  :hover{
    background-color: #F97B22;
    color: white;
  }
`
export const RightSidebar = styled.div`
  width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
`
export const CardView = styled.div`
  height: 200px;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  background-color: #BE5A83;
  color: white;
  /* margin: 1rem; */
`