import React, { useEffect, useState } from "react";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/public/Login";
import { useSelector } from "react-redux";
const App = () => {
  const access_token = useSelector((state) => state?.access_token);
  console.log(access_token, "access_token access_tokenaccess_token")

  useEffect(() => {
    console.log(access_token, "access_token vaccess_token")
  }, [access_token])
  return (
    <div style={{ width: "100%" }}>
      {/* <Dashboard /> */}
      {access_token ? <Dashboard /> : <Login />}
      {/* <Dashboard /> */}
      {/* <Login setLoginStatus={setLoginStatus} /> */}
    </div>
  );
};

export default App;
