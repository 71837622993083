import { ACCESS_TOKEN } from "./actionTypes";

let access_token = localStorage.msgToken;

console.log(access_token, "access_token access_token")
const initialState = {
  access_token: access_token,
};

const customReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case ACCESS_TOKEN:
      return {
        ...state,
        access_token: data,
      };
    default:
      return state;
  }
};

export default customReducer;
