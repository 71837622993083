import React, { useState, useEffect, useRef, useContext } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { contactList, messagesList } from "../mockData";
import EmojiPicker from "emoji-picker-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import Image from "./Image";
import MessageList from "./MessageList";
import FullScreenImage from "./FullScreenImage";
import profilePic from "../assets/download.png";
import userIcon from "../assets/3-User.png";
import searchIcon from "../assets/search.png";
import dotsIcon from "../assets/dots-three.png";
import strokeIcon from "../assets/phone-fill.png";
import vectorIcon from "../assets/video-camera-fill.png";
import backbutton from "../assets/backbutton.jpg";
import LineICon from "../assets/Line.png";
import groupIcon from "../assets/smiley-bold.png";
import plusIcon from "../assets/plus-bold.png";
import audioIcon from "../assets/microphone-fill.png";
import sendIcon from "../assets/send.png";
import closeIcon from "../assets/close.png";
import SeeallIcon from "../assets/Seeall.png";
import Rectangle15 from "../assets/Rectangle15.png";
import Rectangle16 from "../assets/Rectangle16.png";
import Rectangle17 from "../assets/Rectangle17.png";
import plusTenIcon from "../assets/plusTen.png";
import searchChats from "../assets/searchChat.png";
import groupChats from "../assets/groupChat.png";
import userPlus from "../assets/user-plus.png";
import docIcon from "../assets/document.png";
import galleryIcon from "../assets/gallery.png";
import tempIcon from "../assets/template.png";
import {
  Container,
  ProfileHeader,
  CardContainer,
  InnerContainer,
  HeaderContainer,
  Header,
  IconContainer,
  Avatar,
  Typography,
  Profileinfo,
  WelcomePage,
  ProfilePic,
  SelectedProfile,
  BottomCard,
  MessageInput,
  SendButton,
  EmojiImage,
  EmojiPickerContainer,
  PlusBox,
  PlusImage,
  SearchImage,
  AudioImage,
  CustomListItem,
  ListItemContent,
  ContactName,
  LastText,
  LastTextTime,
  SearchIcon,
  EllipsisIcon,
  UsersIcon,
  MessagesHeading,
  StatusText,
  Profilename,
  RightIcon,
  StrokeImage,
  VectorImage,
  SearchI,
  ContactGreenDot,
  RightList,
  UnreadIcon,
  Status,
  Middle,
  LeftCard,
  ProfileBox,
  ProfileInfoContainer,
  CloseIcon,
  ContactDetails,
  Frame,
  ContactProfilePic,
  Contactname,
  Contactphone,
  Line,
  MiddleFrame,
  Seeall,
  ImageBox,
  // Image1,
  // Image2,
  LowerFrame,
  StarredMsgHeading,
  Image3Container,
  // Image3,
  Imageinside3,
  FileInfo,
  FileContainer,
  FileIconContainer,
  FileIcon,
  FileDetails,
  Box,
  FileName,
  FileSize,
  MiddleLeft,
  Headdiv,
  OnlineHeading,
  Onlineno,
  SeeallOnline,
  OnlineProfileBox,
  LMiddle,
  BackButton,
  CancelButton,
  MenuOptions,
  ContactListDiv,
  Profile,
  PlusB,
  ZoomedProfilePic,
  ContentBox,
  SearchContainer,
  SearchBox,
  SearchIconBox,
  SearchInputBox,
  FirstBottom,
  SecondBottom,
  ThirdBottom,
  CloseIconRow,
  InputFeild,
  ButtonRowBoxs,
  TabRow,
  ComButton,
  MenuBox,
} from "./DashEle";
import * as DE from "./DashEle";
import axios from "../utiles/axios";
import userImg from "../assets/user.jpeg";
import { SocketContext } from "../socket";
import { getResponsiveTime, removeDuplicatesById } from "../utiles/helpers";
import moment from "moment";
import * as NE from "../Styled/NavElements";
import { Modal } from "../components/Modal/Modal";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Input from "../components/Input";
import { loginValidator } from "../utiles/validator";
import Switch from "../components/Switch/Switch";
import UploadTrailerOnButton from "../components/UploadTrailerOnButton_Two";
import { UploadFile } from "../utiles/uploadImage";
import { debounce } from "lodash";

const initialValues = {
  phoneNumber: "",
  messageText: "",
};

const Dashboard = () => {
  const socket = useContext(SocketContext);
  const [selectedContact, setSelectedContact] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [pickerVisible, togglePicker] = useState(false);
  const [text, setText] = useState("");
  const [messageList, setMessageList] = useState([]);
  // const [profileInfoOpen, setProfileInfoOpen] = useState(contactList[0]);
  const [profileInfoOpen, setProfileInfoOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [menuOpen, setMenuOpen] = useState(false);
  const fileInputRef = useRef(null);
  const [profileZoomViewOpen, setProfileZoomViewOpen] = useState(false);
  const [zoomedProfilePic, setZoomedProfilePic] = useState(null);
  const [messageText, setMessageText] = useState("");
  const messageContainerRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [popOpen, setPopOpen] = useState(false);
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loginValues, setLoginValues] = useState({
    phoneNumber: "",
    messageText: "",
  });
  const [isActive, setIsActive] = useState({
    active: true,
    pending: false,
  });
  const [isPhoneNumber, setIsPhoneNumber] = useState("");
  const [isRotate, setIsRotate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [feildLoading, setFeildLoading] = useState(false);
  const [contactFeildLoading, setContactFeildLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [contactCurrentPage, setContactCurrentPage] = useState(1);
  const [contactTotalCount, setContactTotalCount] = useState(0);

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [messageList]);

  const userListRef = useRef();

  useEffect(() => {
    // Update window width when the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleBackClick = () => {
    setSelectedContact(null);
  };
  const handleContactClick = (contact) => {
    setSelectedContact(contact);
  };

  const toggleProfileInfo = () => {
    setProfileInfoOpen(!profileInfoOpen);
    console.log("Profile Info Open:", profileInfoOpen);
  };
  const closeProfileInfo = () => {
    console.log("Close icon clicked");

    setProfileInfoOpen(false);
    console.log(profileInfoOpen);
  };

  // const handleSearchInputChange = (event) => {
  //   const text = event.target.value;
  //   setSearchText(text);

  //   const filtered = contactList.filter((contact) =>
  //     contact.name.toLowerCase().includes(text.toLowerCase())
  //   );
  //   setFilteredContacts(filtered);
  // };
  // const handleBackClick = () => {
  //   setSelectedContact(null);
  // };

  const onEmojiClick = (event, emojiObj) => {
    setText(text + emojiObj.emoji);
    togglePicker(false);
  };

  const onEnterPress = async (event) => {
    if (event.key === "Enter") {
      const messages = [...messageList];
      messages.push({
        id: 0,
        messageType: "TEXT",
        text,
        senderID: 0,
        addedOn: "05:09PM",
      });
      setMessageList(messages);
      setText("");
      setTimeout(() => {
        let el = document.getElementById("chat-container");
        if (el && el.scrollHeight) {
          el.scrollTo(0, el.scrollHeight);
        }
      }, 100);
      // let el = document.getElementById("chat-container");
      // if (el && el.scrollHeight) {
      //   el.scrollTo(0, el.scrollHeight + 50);
      // }
    }
  };
  // Dummy user profile data
  const dummyUserProfile = {
    name: "Kazuraba",
    status: "online",
    profilePic: profilePic,
  };
  const onlineContacts = contactList.filter(
    (contact) => contact.ShowOnline === "On"
  );
  const handleImageClick = () => {
    // Trigger the file input for image upload
    fileInputRef.current.accept = "image/*"; // Optional: Limit to image files
    fileInputRef.current.click();
    setMenuOpen(false);
  };

  const handleVideoClick = () => {
    // Trigger the file input for video upload
    fileInputRef.current.accept = "video/*"; // Optional: Limit to video files
    fileInputRef.current.click();
    setMenuOpen(false);
  };

  const handleFileInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleFileUpload = async (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return; // No file selected, do nothing
    }

    const fileType = selectedFile.type.split("/")[0]; // "image" or "video"

    if (fileType === "image") {
      const imageUrl = URL.createObjectURL(selectedFile);
      const newImageMessage = {
        id: messageList.length + 1, // Assign a unique ID based on the length of the current messageList
        messageType: "IMAGE",
        image: [imageUrl],
        senderID: 0,
        addedOn: "05:09PM",
      };
      setMessageList([...messageList, newImageMessage]);
      setTimeout(() => {
        let el = document.getElementById("chat-container");
        if (el && el.scrollHeight) {
          el.scrollTo(0, el.scrollHeight);
        }
      }, 100);
    } else if (fileType === "video") {
      // Handle video upload
      const videoUrl = URL.createObjectURL(selectedFile);
      const newVideoMessage = {
        id: messageList.length + 1, // Assign a unique ID based on the length of the current messageList
        messageType: "VIDEO",
        video: [videoUrl],
        senderID: 0,
        addedOn: "05:09PM",
      };
      setMessageList([...messageList, newVideoMessage]);
      setTimeout(() => {
        let el = document.getElementById("chat-container");
        if (el && el.scrollHeight) {
          el.scrollTo(0, el.scrollHeight);
        }
      }, 100);
    }
    // setFileUploaded(true);
    e.target.value = null;
  };
  const openProfileZoomView = (profilePic) => {
    setZoomedProfilePic(profilePic);
    setProfileZoomViewOpen(true);
  };

  const closeProfileZoomView = () => {
    setZoomedProfilePic(null);
    setProfileZoomViewOpen(false);
  };

  const debouncedLog = debounce((value) => console.log(value), 1000);

  const handleChange = (e) => {
    setInputValue(e.target.value); // Update state with the current input
    debouncedLog(e.target.value);
    setCurrentPage(1);
  };
  
  useEffect(() => {
    const getUserList = async () => {
      setContactFeildLoading(true);
  
      // Clear feedback list only when necessary
      if (contactCurrentPage === 1) {
        setFilteredContacts([]); // Reset feedback list on major parameter change or first page load
      }
  
      try {
        let url = "/messages/list";
        const formData = {
          page: contactCurrentPage,
          limit: 10,
        };
        inputValue && (formData["search"] = inputValue);
        const { data } = await axios.post(url, formData);
        if (data?.data?.result?.docs) {
          setFilteredContacts((prevList) => [
            ...prevList, // Keeps existing data if this is a subsequent page of data
            ...data?.data?.result.docs,
          ]);
        } else if (contactCurrentPage === 1) {
          setFilteredContacts([]); // Clear the feedback list if response is empty and it's the first page
        }
      } catch (err) {
        console.log(err);
      }
    };
    getUserList(true);
  }, [inputValue, contactCurrentPage, isPhoneNumber]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          contactCurrentPage < contactTotalCount &&
          !feildLoading
        ) {
          setContactCurrentPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 1.0 }
    );

    if (userListRef.current) observer.observe(userListRef.current);

    return () => observer.disconnect();
  }, [feildLoading, contactCurrentPage, contactTotalCount]);

  // const getMessages = async (e) => {
  //   try {
  //     let url = "/messages/info";
  //     const formData = {
  //       page: 1,
  //       limit: 10,
  //       phoneNumber: e,
  //     };
  //     const { data } = await axios.post(url, formData);
  //     if (data?.data?.result?.docs) {
  //       setMessageList(data?.data?.result?.docs);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  useEffect(() => {
    const getMessages = async (e) => {
      setFeildLoading(true);

      // Clear feedback list only when necessary
      if (currentPage === 1) {
        setMessageList([]); // Reset feedback list on major parameter change or first page load
      }

      const formValue = {
        limit: 10,
        page: currentPage,
        phoneNumber: isPhoneNumber,
      };

      try {
        let url = "/messages/info";
        const response = await axios.post(url, formValue);
        console.log(response, "response responseresponse");
        if (response?.data?.data?.result?.docs?.length > 0) {
          setMessageList((prevList) => [
            ...prevList, // Keeps existing data if this is a subsequent page of data
            ...response?.data?.data?.result.docs,
          ]);
        } else if (currentPage === 1) {
          setMessageList([]); // Clear the feedback list if response is empty and it's the first page
        }
        setTotalCount(response?.data?.data?.result.totalPages);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
      setFeildLoading(false);
    };
    if (isPhoneNumber) {
      getMessages();
    }
  }, [currentPage, isPhoneNumber]);

  const sendMessage = async () => {
    console.log(messageText, "");
    // alert("eer")

    try {
      let url = "/messages/send";
      const formData = {
        from: selectedContact?.phoneNumber,
        body: text,
        type: "text",
      };
      if (messageText === "") return;
      const { data } = await axios.post(url, formData);
      setText("");
      setIsPhoneNumber(selectedContact?.phoneNumber);
    } catch (error) {
      alert(error);
    }

    // if (messageText) {
    //   try {
    //     const { data } = await axios.post(`/user/send/textMessage`, {
    //       phoneNumber: selectedContact?.phoneNumber,
    //       text: messageText,
    //     });
    //     console.log(data.data, "messages");
    //     // getMessages(selectedContact?.phoneNumber);
    //   } catch (err) {
    //     console.log(err);
    //   }
    // } else {
    //   alert("Enter Message");
    // }
  };

  useEffect(() => {
    console.log("Off New");
    socket.on("new_message", (payload) => {
      console.log(payload, "Messages");
      setIsPhoneNumber(payload?.phoneNumber);
      setCurrentPage(1)
      setContactCurrentPage(1)
      // getUserList();
    });
  }, [socket]);

  console.log(selectedContact);
  const handleClose = () => {
    setOpen(false);
    setPopOpen(false);
  };

  const [inputFields, setInputFields] = useState([
    {
      phoneNumber: "",
      messageText: "",
    },
  ]);

  const handleAddField = () => {
    setInputFields([...inputFields, { phoneNumber: "", messageText: "" }]);
  };

  const handleRemoveField = (index) => {
    const updatedFields = [...inputFields];
    updatedFields.splice(index, 1);
    setInputFields(updatedFields);
  };

  const handleLogin = async (values) => {
    // Transform the form values into the desired format
    const formattedValues = inputFields.map((_, index) => ({
      phoneNumber: values.phoneNumber[index].toString(),
      userName: values.messageText[index],
    }));

    // Now formattedValues is an array of objects with the desired format
    console.log("Formatted Values:", formattedValues);
    if (formattedValues.length > 0) {
      try {
        const { data } = await axios.post(`contacts/save`, {
          contacts: formattedValues,
        });
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("Add User");
    }
  };

  const onTemplate = async (e) => {
    try {
      const { data } = await axios.post(`template`, {
        template_name: "hello_world",
        phoneNumber: e,
      });
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };
  const [fade, setFade] = useState(false);

  const handleButtonClick = () => {
    setFade(true);

    // Remove the 'fade' class after the animation ends
    setTimeout(() => {
      setFade(false);
    }, 1000); // Adjust the time to match the animation duration
  };
  return (
    <>
      <Container className="container">
        <CardContainer
          className="cardcontainer"
          chatSelected={selectedContact !== null}
        >
          <HeaderContainer>
            {" "}
            <Header className="header">
              <Image
                src={dummyUserProfile.profilePic}
                alt={dummyUserProfile.name}
                status={dummyUserProfile.status}
              />
              <IconContainer className="iconcontainer">
                <UsersIcon src={groupChats} />
                <SearchIcon src={searchChats} />
                <SearchIcon
                  src={userPlus}
                  onClick={() => {
                    setOpen(true);
                    setSelectedContact(null);
                  }}
                />
                <EllipsisIcon src={dotsIcon} />
              </IconContainer>
            </Header>
          </HeaderContainer>
          <LeftCard className="left-card">
            {/* <TabRow>
              <ComButton
                isActive={isActive.active}
                onClick={() => {
                  setIsActive({
                    active: true,
                    pending: false,
                  });
                  getUserList(true);
                }}
              >
                Active
              </ComButton>
              <ComButton
                isActive={isActive.pending}
                onClick={() => {
                  setIsActive({
                    active: false,
                    pending: true,
                  });
                  getUserList(false);
                }}
              >
                Pending
              </ComButton>
            </TabRow> */}
            <SearchContainer>
              <SearchBox>
                <SearchIconBox>
                  <img src={searchChats} alt="" />
                </SearchIconBox>
                <SearchInputBox>
                  <input
                    type="text"
                    placeholder="Search or start new chat"
                    onChange={handleChange}
                  />
                </SearchInputBox>
              </SearchBox>
            </SearchContainer>
            <ContactListDiv className="list-div">
              {removeDuplicatesById(filteredContacts)?.map((contact) => {
                return (
                  <CustomListItem
                    className="customlist"
                    key={contact._id}
                    onClick={() => {
                      setIsPhoneNumber(contact?.phoneNumber);
                      setSelectedContact(contact);
                    }}
                  >
                    <ProfileBox className="profilebox">
                      <img
                        src={contact.profilePic || userImg}
                        alt={contact.profileName}
                        // status={contact.status}
                      />
                    </ProfileBox>
                    <ContentBox>
                      <ListItemContent className="list-item-content">
                        <ContactName className="contact-name">
                          {contact?.profileName || contact?.phoneNumber}
                        </ContactName>
                        {contact.seen === "unread" ? (
                          <LastText className="last-text" isUnread>
                            {contact.lastMessage}
                          </LastText>
                        ) : (
                          <LastText>{contact.lastMessage}</LastText>
                        )}
                      </ListItemContent>
                      <RightList className="right-list">
                        <LastTextTime hasUnread={contact.seen === "unread"}>
                          {moment(contact?.timestamp).calendar(null, {
                            sameDay: "[Today]",
                            nextDay: "[Tomorrow]",
                            nextWeek: "DD-MM-YYYY",
                            lastDay: "[Yesterday]",
                            lastWeek: "DD/MM/YY",
                            sameElse: "DD/MM/YY",
                          })}
                        </LastTextTime>
                        {contact.seen === "unread" && (
                          <UnreadIcon hasUnread={true}>1</UnreadIcon>
                        )}
                      </RightList>
                    </ContentBox>
                  </CustomListItem>
                );
              })}

              <div
                ref={userListRef}
                style={{ height: "1px", width: "100%" }}
              ></div>
            </ContactListDiv>
          </LeftCard>
        </CardContainer>
        <InnerContainer chatSelected={selectedContact !== null}>
          {selectedContact ? (
            <>
              <ProfileHeader
                className="profile-header"
                onClick={toggleProfileInfo}
              >
                <Profile className="profile-name">
                  <SelectedProfile
                    className="selected-profile"
                    src={selectedContact.profilePic || userImg}
                  />
                  <Middle className="middle">
                    <Profilename className="profile-name">
                      {selectedContact.profileName || selectedContact.phoneNumber}
                    </Profilename>
                  </Middle>
                </Profile>
                {/* {!profileInfoOpen && ( */}
                <RightIcon className="right-icon" windowWidth={windowWidth}>
                  <StrokeImage src={strokeIcon} />
                  <VectorImage src={vectorIcon} />
                  <Switch
                    rounded={true}
                    isToggled={true}
                    bgColorChecked={"#54656f"}
                    roundColorChecked="#ffffff"
                    border={"1px solid transparent"}
                    marLeft={true}
                    bgColorUnChecked={"#1F242F"}
                    roundColorUnChecked="#ffffff"
                    onToggle={() => {
                      // formikBag.setFieldValue(
                      //   "active",
                      //   !formikBag.values.active
                      // );
                    }}
                  />
                  <SearchI src={searchChats} />
                </RightIcon>
                {/* )} */}
              </ProfileHeader>
              <MessageList
                messageList={messageList}
                ref={messageContainerRef}
                handleCurrentPage={setCurrentPage}
                totalCount={totalCount}
                feildLoading={feildLoading}
                currentPage={currentPage}
              />

              <BottomCard>
                <FirstBottom>
                  {/* <EmojiPickerContainer>
                    {pickerVisible && (
                      <EmojiPicker
                        onEmojiClick={onEmojiClick}
                        pickerStyle={{
                          position: "absolute",
                        }}
                      />
                    )}
                  </EmojiPickerContainer> */}
                  <EmojiImage
                    src={groupIcon}
                    onClick={() => togglePicker(!pickerVisible)}
                  />

                  <EmojiImage
                    src={plusIcon}
                    className={fade ? "fade" : ""}
                    isRev={isRotate}
                    onClick={() => {
                      setMenuOpen(!menuOpen);
                      handleButtonClick();
                      setIsRotate(!isRotate);
                    }} // Toggle the menu state when PlusImage is clicked
                  />
                  {/* <Button onClick={handleButtonClick}>Animate</Button> */}
                  <MenuBox isMenu={isRotate}>
                    {/* <button>Document</button>
                    <button>Photo & Videos</button>
                    <button
                      onClick={() => {
                        setPopOpen(true);
                      }}
                    >
                      Template
                    </button> */}
                    <DE.MenuRow>
                      <DE.MenuBoxOne>
                        <img src={docIcon} alt="" />
                      </DE.MenuBoxOne>
                      <DE.MenuBoxTwo>Document</DE.MenuBoxTwo>
                    </DE.MenuRow>
                    <DE.MenuRow>
                      <DE.MenuBoxOne>
                        <img src={galleryIcon} alt="" />
                      </DE.MenuBoxOne>
                      <DE.MenuBoxTwo>Photos & Videos</DE.MenuBoxTwo>
                    </DE.MenuRow>
                    <DE.MenuRow>
                      <DE.MenuBoxOne>
                        <img src={tempIcon} alt="" />
                      </DE.MenuBoxOne>
                      <DE.MenuBoxTwo
                        onClick={() => {
                          setPopOpen(true);
                        }}
                      >
                        Template
                      </DE.MenuBoxTwo>
                    </DE.MenuRow>
                  </MenuBox>
                  {/* <MenuOptions className="menu-option" menuOpen={menuOpen}>
                    <button onClick={handleImageClick}>Image</button>
                    <button onClick={handleVideoClick}>Video</button>
                  </MenuOptions> */}
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                  />
                </FirstBottom>
                <SecondBottom>
                  <MessageInput
                    placeholder="Write a message"
                    value={text}
                    onKeyDown={(e) => setMessageText(e.target.value)}
                    onChange={(e) => setText(e.target.value)}
                  />
                </SecondBottom>
                <ThirdBottom>
                  {/* <AudioImage src={audioIcon} /> */}
                  <button onClick={sendMessage}>
                    <img src={sendIcon} alt="send-button" />
                  </button>
                </ThirdBottom>
              </BottomCard>
            </>
          ) : open ? (
            <NE.PopUpContainer>
              <NE.InnerPopup>
                <NE.PopTitle>Send Users</NE.PopTitle>
                <Formik
                  initialValues={{
                    phoneNumber: inputFields.map((field) => field.phoneNumber),
                    messageText: inputFields.map((field) => field.messageText),
                  }}
                  validateOnChange
                  onSubmit={(values) => handleLogin(values)}
                >
                  {() => (
                    <Form style={{ width: "100%" }}>
                      <NE.ButtonRowBoxs>
                        <button
                          type="submit"
                          style={{ backgroundColor: "#A8DF8E" }}
                          onClick={handleAddField}
                        >
                          Add More
                        </button>
                      </NE.ButtonRowBoxs>
                      {inputFields.map((_, index) => (
                        <div key={index}>
                          <NE.PopInputBox>
                            <NE.PopLabel>User Name</NE.PopLabel>
                            <NE.PopInput>
                              <InputFeild
                                name={`messageText[${index}]`}
                                type="text"
                                placeholder="Enter Message"
                              />
                              <ErrorMessage
                                name={`messageText[${index}]`}
                                component="div"
                                className="error"
                              />
                            </NE.PopInput>
                          </NE.PopInputBox>
                          <NE.PopInputBox>
                            <NE.PopLabel>Mobile Number</NE.PopLabel>
                            <NE.PopInput>
                              <InputFeild
                                name={`phoneNumber[${index}]`}
                                type="number"
                                placeholder="Enter Mobile Number"
                              />
                              <ErrorMessage
                                name={`phoneNumber[${index}]`}
                                component="div"
                                className="error"
                              />
                            </NE.PopInput>
                          </NE.PopInputBox>
                          {/* <button
                            type="button"
                            onClick={() => handleRemoveField(index)}
                          >
                            Remove
                          </button> */}
                          <NE.ButtonRowBoxs>
                            <button
                              type="submit"
                              style={{ backgroundColor: "#F31559" }}
                              onClick={() => handleRemoveField(index)}
                            >
                              Remove
                            </button>
                          </NE.ButtonRowBoxs>
                        </div>
                      ))}
                      {/* <button type="button" onClick={handleAddField}>
                        Add More
                      </button> */}
                      <NE.ButtonRow>
                        <NE.ButtonRowBox>
                          <button
                            type="submit"
                            style={{ backgroundColor: "#FF7B54" }}
                          >
                            Save & Exit
                          </button>
                        </NE.ButtonRowBox>
                        <NE.ButtonRowBox>
                          <button
                            type="submit"
                            style={{ backgroundColor: "#FF577F" }}
                          >
                            Save & Message
                          </button>
                        </NE.ButtonRowBox>
                      </NE.ButtonRow>
                    </Form>
                  )}
                </Formik>
              </NE.InnerPopup>
            </NE.PopUpContainer>
          ) : (
            <WelcomePage>
              <Typography>Select a contact to start a conversation.</Typography>
            </WelcomePage>
          )}
        </InnerContainer>

        <ProfileInfoContainer
          profileInfoOpen={profileInfoOpen}
          windowWidth={windowWidth}
        >
          <ContactDetails className="contact-details">
            {profileInfoOpen && (
              <>
                <CloseIconRow>
                  <CloseIcon
                    src={closeIcon}
                    alt="Close"
                    onClick={closeProfileInfo}
                  />
                </CloseIconRow>
                <Frame>
                  {selectedContact && (
                    <>
                      {/* <ContactProfilePic
                        src={selectedContact.profilePic || userImg}
                        onClick={() =>
                          openProfileZoomView(selectedContact?.profilePic)
                        }
                      /> */}
                      <UploadTrailerOnButton
                        type="file"
                        id="picos"
                        accept="image/jpeg, image/png"
                        accepTed="image/"
                        alertMsg="No CSV Found"
                        description="jpg, png (Max. 5 MB)"
                        profilePhoto={selectedContact.profilePic || userImg}
                        onChange={async (e) => {
                          const file = e.target.files[0];
                          const sanitizedFileName = file.name?.replace(
                            /[^a-zA-Z0-9_.]+/g,
                            "_"
                          );

                          const fileSize = e.target.files[0].size / 1024 / 1024; // in MiB
                          if (fileSize > 100) {
                            alert("Each file must be 100MB or less");
                            // $(file).val(''); //for clearing with Jquery
                          } else {
                            // Function to check if the file is CSV or Excel
                            const isCsvOrExcel = (file) => {
                              return (
                                file?.type === "image/jpeg" ||
                                file?.type === "image/png"
                              );
                            };

                            if (!isCsvOrExcel(file)) {
                              alert("file format is not supported");
                            } else {
                              setIsLoadingUpload(true);
                              const sanitizedFile = new File(
                                [file],
                                sanitizedFileName,
                                { type: file.type }
                              );

                              const image = await UploadFile(
                                sanitizedFile,
                                setUploadProgress
                              );
                              if (image) {
                                setIsLoadingUpload(false);
                                setUploadProgress(0);
                                // formikBag.setFieldValue(
                                //   `profileImage`,
                                //   image
                                // );
                                // }
                              }
                            }
                            e.target.value = "";
                          }
                        }}
                      />
                      {profileZoomViewOpen && (
                        <FullScreenImage
                          imageUrl={zoomedProfilePic || userImg}
                          onClose={closeProfileZoomView}
                        />
                      )}

                      <Contactname>{selectedContact.name}</Contactname>
                      <Contactphone>{selectedContact.number}</Contactphone>
                    </>
                  )}

                  <Line src={LineICon} />
                </Frame>
                <MiddleFrame>
                  Media
                  <Seeall src={SeeallIcon} />
                </MiddleFrame>
                <ImageBox>
                  <img src={Rectangle15} alt="" />
                  <img src={Rectangle16} alt="" />
                  <Image3Container>
                    <img src={Rectangle17} alt="Image3" />
                    <Imageinside3 src={plusTenIcon} alt="Image Inside 3" />
                  </Image3Container>
                </ImageBox>
                <LowerFrame>
                  Files
                  <Seeall src={SeeallIcon} />
                </LowerFrame>
                <FileInfo>
                  {selectedContact?.files?.map((file, index) => (
                    <FileContainer key={index}>
                      <FileIconContainer>
                        <FileIcon
                          src={file.fileicon || userImg}
                          alt="File Icon"
                        />
                      </FileIconContainer>

                      <FileDetails>
                        <FileName>{file.filename}</FileName>
                        <FileSize>{file.filesize}</FileSize>
                      </FileDetails>
                    </FileContainer>
                  ))}
                </FileInfo>
                <StarredMsgHeading>
                  Starred Messages
                  <Seeall src={SeeallIcon} />
                </StarredMsgHeading>
              </>
            )}

            {profileInfoOpen && <div></div>}
          </ContactDetails>
        </ProfileInfoContainer>
      </Container>
      {/* Add User */}
      <Modal
        isOpen={popOpen}
        onClose={handleClose}
        maxWidth="400px"
        title={
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "right",
              fontSize: "2rem",
              color: "#EA5455",
            }}
            className="closeicon"
            onClick={handleClose}
          >
            <i
              className="ph-fill ph-x-circle"
              style={{ cursor: "pointer" }}
            ></i>
          </div>
        }
        content={
          <>
            <NE.PopTitle>Template</NE.PopTitle>
            <NE.ModalCon>
              <NE.leftSidebar>
                <NE.LeftMenu>Hellow World</NE.LeftMenu>
                <NE.LeftMenu>ABout</NE.LeftMenu>
                <NE.LeftMenu>Contact</NE.LeftMenu>
                <NE.LeftMenu>Blog</NE.LeftMenu>
              </NE.leftSidebar>
              <NE.RightSidebar>
                <NE.CardView>Test 1</NE.CardView>
                {/* <NE.CardView>Test 2</NE.CardView>
                <NE.CardView>Test 3</NE.CardView>
                <NE.CardView>Test 4</NE.CardView> */}
                <NE.ButtonRowBoxs
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <button
                    type="submit"
                    style={{ backgroundColor: "#F97B22", marginTop: "1rem" }}
                    onClick={() => {
                      // alert("Submitted!");
                      onTemplate(selectedContact?.phoneNumber);
                    }}
                  >
                    Submit
                  </button>
                </NE.ButtonRowBoxs>
              </NE.RightSidebar>
            </NE.ModalCon>
          </>
        }
      />
    </>
  );
};

export default Dashboard;
