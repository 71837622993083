import styled from "styled-components/macro";
import {
  commonStyles,
  colorProvider,
  fontProvider,
  fontWeight,
} from "./StylePropertiy";


export const ProfileSubDesc = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  p {
    font-size: 0.9rem;
    color: white;
    font-weight: 400;
    line-height: 20px;
  }
`;

export const ReadMoreText = styled.div`
  cursor: ${commonStyles.pointer};
  color: green;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  p {
    color: white;
    font-size: ${fontProvider.zeroEig};
    font-weight: 700;
    border-bottom: ${(props) =>
      props.themeColor
        ? `1px solid ${colorProvider.greyFive}`
        : `1px solid ${colorProvider.greyFive}`};
    padding-bottom: 0.01rem;
    margin-top: 0.5rem;
    text-align: left;
  }
`;