import { ACCESS_TOKEN } from "./actionTypes";

const setAccessToken = (data) => {
  return {
    type: ACCESS_TOKEN,
    data,
  };
};

export { setAccessToken };
