import { useContext } from "react";

// global regex
const noHtmlRegex = /<\/?[^>]+(>|$)/g;
const onlyAlphbetRegex = /^[a-zA-Z ]*$/;
const regNum = /[^a-zA-Z0-9 ]/;
const numberOnly = /^\d+$/;
var phoneRegex = /^[0-9]+$/;
var numFeild = /^[+-]?\d+$/;
var passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{7,}$/;
// const { dictionary } = useContext(LanguageContext);
// console.log(messages);
const data = JSON.parse(localStorage.getItem("userData"));
let feildCannots = "This is an error message";
const checkEmail = (value) => {
  if (
    !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
  ) {
    return true;
  } else if (
    value.includes('"') ||
    value.includes("'") ||
    value.includes(",") ||
    value.includes(" ")
  ) {
    return true;
  } else {
    return false;
  }
};

// export const loginValidator = (values) => {
//   let errors = {};
//   if (!values.phoneNumber) {
//     errors.phoneNumber = "Enter Mobile Number";
//   }
//   if (!values.messageText) {
//     errors.messageText = "Enter Message";
//   }
//   return errors;
// };


export const loginValidator = (values) => {
  let errors = {};
  if (!values.email) {
    errors.email = feildCannots;
  } else if (checkEmail(values.email)) {
    errors.email = "Invalid Email";
  }
  if (!values.password) {
    errors.password = feildCannots;
  }
  return errors;
};