import React from 'react';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import './modal.css'
const useStyles = makeStyles({
    dialogPadding: {
    }
  });

const noop = () => {};
export const Modal = ({ 
    isOpen= false, 
    title = "title",
    content = "content",
    onClose = noop,  
    maxWidth,
    width="100%",
    setShowModal 
}) => {
    const classes = useStyles();
    return (
        <>
            {isOpen ? (
                <Dialog
                    open={isOpen}
                    onClose={onClose}
                    maxWidth={maxWidth}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                <div className={classes.dialogPadding} style={{width:width}}>
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent>{content}</DialogContent>
                </div>
                </Dialog>
                ) : null}

        </>
    )
};