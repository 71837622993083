import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { socket, SocketContext } from "./socket";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./pages/auth/auth-config";
import { Provider } from "react-redux";
import customReducer from "./store/reducers";
import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
  reducer: customReducer, // Your root reducer
});
const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <SocketContext.Provider value={socket}>
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <App />
        </Provider>
      </MsalProvider>
    </React.StrictMode>
  </SocketContext.Provider>
);
