export const getResponsiveTime = (e) => {
    if (e) {
      const today = new Date();
      console.log(e, 'time');
      const endDate = new Date(e * 1000); // Convert to milliseconds
      const secondsPassed = parseInt(Math.abs(endDate - today) / 1000);
      const minutesPassed = parseInt(secondsPassed / 60);
      const hoursPassed = parseInt(minutesPassed / 60);
      const daysPassed = parseInt(hoursPassed / 24);
  
      if (daysPassed > 365) {
        return `${Math.round(daysPassed / 365)} yr`;
      } else if (daysPassed < 365 && daysPassed > 28) {
        return `${Math.round(daysPassed / 28)} mon`;
      } else if (daysPassed < 28 && daysPassed > 10) {
        return `${daysPassed} day ago`;
      } else if (daysPassed < 10 && daysPassed > 1) {
        return `${daysPassed} days ago`;
      } else if (hoursPassed < 24 && hoursPassed > 1) {
        return `${hoursPassed} hours ago`;
      } else if (minutesPassed < 60 && minutesPassed > 1) {
        return `${minutesPassed} minutes ago`;
      } else {
        return `${secondsPassed} seconds ago`;
      }
    } else {
      return "";
    }
  };
  
  export function removeDuplicatesById(arr) {
    const seenIds = new Set();
    return arr.filter((obj) => {
      if (seenIds.has(obj._id)) {
        return false; // Duplicate _id, filter it out
      }
      seenIds.add(obj._id);
      return true; // Unique _id, keep it
    });
  }