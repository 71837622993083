import React, { useState } from "react";
import { ProfileSubDesc, ReadMoreText } from "./SectionStyled";

const MoreLess = ({ desc, theme }) => {
  const [showMore, setShowMore] = useState(false);
  function removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, "");
  }
  return (
    <div style={{ width: "100%" }}>
      <ProfileSubDesc themeColor={theme}>
        <p>
          {showMore
            ? removeTags(desc)
            : `${removeTags(desc).substring(0, 150)} ${ desc?.length > 150 ? "..." : "" }`}
        </p>
      </ProfileSubDesc>
      {
        desc?.length > 150 ? <ReadMoreText themeColor={theme} onClick={() => setShowMore(!showMore)}>
        <p>
          {" "}
          {desc?.length > 150 ? (showMore ? "Read Less" : "Read More") : ""}
        </p>
      </ReadMoreText> : ""
      }
      
    </div>
  );
};

export default MoreLess;
